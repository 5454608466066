import React from 'react';
import Template from '../layouts/Template';
import { PostLink } from '../templates/blog';

const interestingLinks = [
	{
		href: 'https://acloud.guru/',
		title: 'A Cloud Guru',
		description:
			"I've been working towards taking some AWS Certification tests. I have used almost every AWS service covered in the AWS Certified Solutions Architect Professional test, but it would be nice to have a certification.",
	},
	{
		href: 'https://github.com/softprops/serverless-rust',
		title: 'Serverless Rust Plugin',
		description:
			'Part of the issue with learning a new language is learning how to host and run it...The serverless rust plugin makes this super simple.',
	},
	{
		href: 'https://github.com/rust-lang/rustlings',
		title: 'Rustlings: Learning Rust',
		description:
			'This project contains small exercises to get you used to reading and writing Rust code. This includes reading and responding to compiler messages!',
	},
	{
		href: 'https://lawsofux.com/',
		title: 'Laws of UX',
		description: 'A good list of ux things to keep in mind.',
	},
	{
		href: 'https://www.dynamodbbook.com/',
		title: 'The DynamoDb Book',
		description: 'Great book on learning how to model data structures in DDB.',
	},
	{ href: 'https://github.com/rust-lang/rustlings', title: 'Rustling Exercise', description: 'A fun introduction to Rust.' },
	{
		href: 'https://www.dynamodbguide.com/the-dynamo-paper',
		title: 'The Dynamo Paper',
		description: 'A good introduction to the DynamoDB paper and DDB',
	},
	{
		href: 'http://book.mixu.net/distsys/single-page.html',
		title: 'Distributed systems',
		description:
			'This short book is a great overview of using modern distributed databases. It gives you a framework for designing durable modern systems.',
	},
	{
		href: 'https://testingjavascript.com/',
		title: 'Testing JS',
		description:
			'One of my side-projects at work has been to autogenerate a mocked api for testing all consumers of the api, and a standard query library that can be used in our React app and other Serverless apps. The project uses the GraphQL Schema exported by the backend to automatically validate or throw an error to every query/mutation called from the frontend or other serverless apps. This makes it incredibly easy to test. Testing Javascript is a good course for those looking to understand mocking at this deep of a level.',
	},
	{
		href: 'https://www.amazon.com/Cracking-Coding-Interview-Programming-Questions/dp/0984782850/',
		title: 'Cracking the Coding Interview',
		description: "I'm learning rust by implementing all 189 of these problems in the new language. It's a good challenge.",
	},
	{
		href: 'https://www.udemy.com/course/aws-serverless-glue-redshift-spectrum-athena-quicksight-training/',
		title: 'AWS Glue And Serverless Data Lakes',
		description:
			'Data lakes are becoming more and more normal in applications. This is a great overview of data lakes and implementing one in a cloud native way.',
	},
	{
		href: 'https://executeprogram.com',
		title: 'Basic, but good ux',
		description:
			'If you are ever onboarding a new team member who needs to brush up their JS, this site has a really good way of teaching js/ts.',
	},
];

export default function Interesting() {
	return (
		<Template title="Things I Find Interesting" desc="Interesting things.">
			<section>
				<div className="wrapper">
					<header>
						<h1>Interesting links</h1>
					</header>
					<div>
						{interestingLinks.map(({ href, title, description }) => (
							<PostLink key={href}>
								<a
									style={{ fontSize: '2em', paddingBottom: '.5rem', textDecoration: 'underline' }}
									href={href}
									target="_blank"
									rel="noopener noreferrer"
								>
									{title}
								</a>
								<div style={{ fontSize: '1.2em', lineHeight: '1.5' }}>{description}</div>
							</PostLink>
						))}
					</div>
				</div>
			</section>
		</Template>
	);
}
