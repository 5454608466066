import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Link from 'gatsby-link';
import styled from 'styled-components';

import Template from '../layouts/Template';

const BlogSection = styled.section`
	header {
		${tw`mb-10`};
	}
	h1 {
		${tw`text-64 mb-8`};
	}
	.subtitle {
		${tw`font-sans text-24 sm:text-20 opacity-50 tracking-wide`};
		text-transform: uppercase;
	}
	.link {
		${tw`font-sans text-24 sm:text-20 opacity-50 tracking-wide`};
	}
`;

export const PostLink = styled.div`
	${tw`mb-8`};
`;

const NavLink = (props) => {
	const { url, text, margin } = props;

	return (
		<Link style={{ paddingLeft: margin ? '1em' : '' }} to={url}>
			{text}
		</Link>
	);
};

NavLink.propTypes = {
	url: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	margin: PropTypes.bool.isRequired,
};

const BlogIndex = (props) => {
	const { data, pageContext } = props;
	const siteTitle = data.site.siteMetadata.title;

	const { group, index, first, last } = pageContext;
	const previousUrl = index - 1 === 1 ? '' : (index - 1).toString();
	const nextUrl = (index + 1).toString();

	return (
		<Template title={`Blog | ${siteTitle}`} desc="The main blog page">
			<BlogSection>
				<div className="wrapper">
					<header>
						<h1>Blog</h1>
					</header>

					<PostLink>
						<Link to="/interesting/">
							<p>Things I Find Interesting</p>
						</Link>
					</PostLink>

					{group.map(({ node }) => (
						<PostLink key={node.fields.slug}>
							<Link to={node.fields.slug}>
								<p>{node.frontmatter.title}</p>
								<h5>{node.frontmatter.date}</h5>
							</Link>
						</PostLink>
					))}
					<div className="link">
						{!first && <NavLink margin={false} url={`/blog/${previousUrl}/`} text="Previous Page" />}
						{!last && <NavLink margin={!first} url={`/blog/${nextUrl}/`} text="Next Page" />}
					</div>
				</div>
			</BlogSection>
		</Template>
	);
};

BlogIndex.propTypes = {
	data: PropTypes.object.isRequired,
	pageContext: PropTypes.object.isRequired,
};

export default BlogIndex;

export const blogQuery = graphql`
	query BlogQuery {
		site {
			siteMetadata {
				title
			}
		}
	}
`;
